@use "../../styles/global" as *;

.image {
    box-sizing: content-box;
    overflow: hidden;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 #0000ff10;

    img {
        display: block;
        max-width: 100%;
        height: auto;
        transition: $transition-duration ease-out;
    }

    &:hover img {
        transform: scale(1.1);
    }
}
