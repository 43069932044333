:root {
    --primary: #E8BF96;
    --blue: #52526c;

    /* White */
    --color-white-800: #F0ECE9;
    --color-white-700: #EFEFEF;
    --color-white-600: #F3F3F3;
    --color-white-500: #F5F5F5;
    --color-white-400: #F9F9F9;
    --color-white-300: #FBFAF7;
    --color-white-200: #fff;

    /* Black */
    --color-black-800: #1C1D20;
    --color-black-700: #212125;
    --color-black-600: #262629;
    --color-black-500: #27272B;
    --color-black-400: #333337;
    --color-black-300: #3C3636;

    /* Gray */
    --color-gray-600: #666;
    --color-gray-500: #999;
}

[data-theme='light'] {
    --color-logo: var(--color-black-800);
    --color-bg-level-0: var(--color-white-400);
    --color-bg-level-1: var(--color-white-500);
    --color-bg-level-2: var(--color-white-300);
    --color-text-primary: var(--color-black-800);
    --color-text-secondary: var(--color-gray-600);
    --color-bg-underlines: var(--color-white-700);

    // Skills
    --color-border-management: #2B4570;
    --color-text-management: #2B4570;
    --color-border-programming: #43AA8B;
    --color-text-programming: #43AA8B;
    --color-border-blogging: #D64933;
    --color-text-blogging: #D64933;
    --color-border-consulting: #9F87AF;
    --color-text-consulting: #9F87AF;

    // Badge
    --color-bg-badge: var(--color-white-800);
    --color-text-badge: var(--color-gray-600);

    // Tabs
    --color-bg-tabs: var(--color-white-600);

    // Aside buttons
    --color-bg-aside-btn: var(--color-black-400);
    --color-border-aside-btn: var(--color-gray-600);
    --color-border-active-aside-btn: var(--primary);
    --color-text-aside-btn: var(--color-white-200);
    --color-text-hover-aside-btn: var(--primary);

    // Secondary heading
    --color-text-secondary-heading: var(--color-black-300);
}

[data-theme='dark'] {
    --color-logo: var(--color-white-200);
    --color-bg-level-0: var(--color-black-800);
    --color-bg-level-1: var(--color-black-700);
    --color-bg-level-2: var(--color-black-600);
    --color-text-primary: var(--color-white-200);
    --color-text-secondary: var(--color-gray-500);
    --color-bg-underlines: var(--color-black-500);

    // Skills
    --color-border-management: #ACFCD9;
    --color-text-management: #ACFCD9;
    --color-border-programming: #0ACDFF;
    --color-text-programming: #0ACDFF;
    --color-border-blogging: #C32F27;
    --color-text-blogging: #C32F27;
    --color-border-consulting: #D1BCE3;
    --color-text-consulting: #D1BCE3;

    // Badge
    --color-bg-badge: var(--color-black-300);
    --color-text-badge: var(--color-gray-500);

    // Tabs
    --color-bg-tabs: var(--color-black-600);

    // Aside buttons
    --color-bg-aside-btn: var(--color-black-400);
    --color-border-aside-btn: var(--color-gray-600);
    --color-border-active-aside-btn: var(--primary);
    --color-text-aside-btn: var(--color-white-200);
    --color-text-hover-aside-btn: var(--primary);

    // Secondary heading
    --color-text-secondary-heading: var(--color-white-800);
}
