@use "../../styles/global" as *;

.youtube-video-container {
    position: relative;
    float: right;
    margin-bottom: $gap;
    margin-left: $gap;
    overflow: hidden;
    border: 2px solid var(--color-text-primary);

    .video-iframe,
    .thumbnail-img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 0;
    }
}

.video-thumbnail {
    cursor: pointer;
    background-color: black;

    &::before {
        position: absolute;
        inset: 0;
        content: "";
        background-color: #00000060;
    }

    .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 48px;
        color: #f00;
        transform: translate(-50%, -50%);
    }
}