@use "../../styles/global" as *;

.scroll-top {
    position: fixed;
    right: $base * 10;
    bottom: $base * 10;
    z-index: 7;
    display: block;
    width: 50px;
    height: 50px;
    color: var(--color-text-aside-btn);
    cursor: pointer;
    visibility: hidden;
    background-color: var(--color-bg-aside-btn);
    border: 2px solid var(--color-border-active-aside-btn);
    border-radius: 100%;
    opacity: 0;
    transition: $transition-duration;
    transform: translateY(25px);

    &_status-active {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    &:hover {
        color: var(--color-text-hover-aside-btn);
    }

    &__arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        margin: 0 auto;
        font-size: $font-size-m;
        transform: translate(-50%, -50%);
    }
}
