@use "../../styles/global" as *;

.section-hero {
    position: relative;
    height: 100vh;
    min-height: 500px;
    overflow: hidden;
    font-family: $font-family-heading;

    .g-inner {
        position: relative;
        height: 100%;
    }

    &__position {
        min-height: $line-height-l;
        margin-bottom: $base * 4;
        font-size: $font-size-l;
        font-weight: $font-weight-600;
        color: var(--primary);
        text-transform: uppercase;
        white-space: nowrap;
    }

    &__name {
        margin: 0 0 $base * 10;
        font-size: $font-size-xxl;
        font-weight: $font-weight-500;
        line-height: $line-height-xxl;
        text-transform: uppercase;
        white-space: pre;
    }

    &__follow-me,
    &__write-to-me,
    &__download-cv {
        color: var(--color-gray-500);
    }

    &__write-to-me,
    &__download-cv { margin-top: $base * 10; }

    &__columns {
        display: flex;
        height: 100%;

        div {
            flex: 1;

            &:nth-child(1) {
                margin-top: 20vh;
                background-color: var(--primary);
            }

            &:nth-child(2) {
                background-color: var(--blue);
            }
        }
    }

    &__years-count {
        font-size: $font-size-xxl;
        font-weight: $font-weight-400;
    }

    &__years-text {
        font-size: $font-size-xs;
        font-weight: $font-weight-500;
        text-transform: uppercase;
    }

    &__img {
        position: absolute;
        right: -20px;
        bottom: 0;
        width: auto;
        height: auto;
        max-height: 100vh;
    }

    @media (min-width: $screen-md-min) {
        &__content-left {
            position: absolute;
            top: 20%;
            left: 0;
            z-index: 2;
        }
    
        &__content-right {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
        }

        &__img { max-width: 500px; }
    }

    @media (min-width: $screen-lg-min) {
        &__img { max-width: 800px; }
    }

    @media (max-width: $screen-sm-max) {
        .g-inner {
            display: flex;
            flex-direction: column;
        }

        &__content-left {
            position: relative;
            z-index: 2;
            padding-top: $base * 40;
        }
    
        &__content-right {
            position: relative;
            flex: 1;
        }

        &__position { font-size: $font-size-m; }
        &__name { font-size: rem(60px); }

        &__img { max-width: 450px; }
    }

    @media (max-width: $screen-xs-max) {
        &__img { max-width: 270px; }
    }
}

.btn-download-cv {
    @include border-btm(var(--primary), var(--primary));

    display: inline-flex;
    gap: $base * 2;
    align-items: center;
    color: inherit;
    text-decoration: none;
    transition: $transition-duration;

    &__icon {
        font-size: $font-size-s;
    }

    &__text {
        font-size: $font-size-xs;
        font-weight: $font-weight-500;
        text-transform: uppercase;
    }
}
