@use "../../styles/global" as *;

.color-bg-underlines {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    display: flex;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.bg-line {
    position: relative;
    min-width: 25%;
    border-right: 1px solid var(--color-bg-underlines);

    &::before {
        position: absolute;
        top: -80px;
        right: -1px;
        width: 1px;
        height: 80px;
        content: '';
        background: linear-gradient(transparent, var(--primary));
        opacity: .7;
        animation: bg-line 8s linear infinite;
    }

    &_line-2::before { animation-delay: 2s; }
    &_line-3::before { animation-delay: 4s; }
}

@keyframes bg-line {
    0% { top: -80px; }
    100% { top: 100%; }
}

[data-theme='dark'] .bg-line::before { opacity: .3; }
