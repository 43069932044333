@use "./variables.scss" as *;

@mixin border-btm($color, $hcolor) {
    position: relative;
    padding-bottom: 2px;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        content: "";
        background: $color;
        transition: $transition-duration;
    }

    &:hover {
        color: $hcolor;

        &::after {
            width: 0%;
            background: $hcolor;
        }
    }
}

@mixin section-indent($indent: $base * 15) {
    padding-top: $indent;
    padding-bottom: $indent;
}

@mixin article-typo() {
    .text-subheading {
        padding: 0;
        margin: 0 0 $base;
        font-family: $font-family-heading;
        font-size: $font-size-s;
        font-weight: $font-weight-500;
        text-transform: uppercase;
    
        &:not(:first-child) {
            margin-top: $base * 5;
        }
    }
    
    a {
        font-weight: $font-weight-600;
        color: inherit;
        text-decoration: none;
        transition: $transition-duration;
    
        @include border-btm(var(--color-text-primary), var(--primary));
    }
    
    p {
        padding: 0;
        margin: 0;
    
        & + p { margin-top: $base * 2; }
    }

    ol {
        padding: 0 0 0 $base * 5;
        margin: 0;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style: none;

        li:not(:last-child)::after {
            content: ",\00A0";
        }
    }
}
